<template>
  <div>
    <b-modal
      v-model="show_modal_pagamento"
      id="modal-xl"
      size="xl"
      title="Confirmando Pagamento"
      hide-footer
      header-bg-variant="secondary"
      @hidden="voltar()"
    >
      <div class="card">
        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-4 text-center">
              <Label>{{ "*Espécie de Pagemento" }}</Label>
              <multiselect
                v-model="select_pagamento"
                :options="especie_pagamentos"
                label="nome"
                @select="selecionar_Pagamento($event)"
              >
              </multiselect>
            </div>
            <div class="col-md-4 text-center">
              <Label>{{ "*Descrição" }}</Label>
              <input
                v-model="pagamento_objeto.desc"
                type="text"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-4 text-center">
              <label for="">N° Doc</label>
              <input
                v-model="pagamento_objeto.num_doc"
                type="text"
                class="form-control text-left"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="">{{ "Data de Vencimento" }}</label>
              <input
                v-model="pagamento_objeto.data_parcela"
                type="date"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-4">
              <label for="">{{ "Data de Pagamento" }}</label>
              <input
                v-model="pagamento_objeto.data_fatura"
                type="date"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-4">
              <label for="">{{ "*Conta" }}</label>
              <multiselect v-model="select_conta" @select="selecionar_conta($event)" :options="contas" label="nome"> </multiselect>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <label for="">Observações</label>
              <textarea
                v-model="pagamento_objeto.obs"
                name=""
                id=""
                cols="30"
                rows="10"
                class="form-control text-left"
              ></textarea>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-2 mt-3">
              <label for="" class="fonte2">
                <b-badge
                  data-name="credito"
                  class="field-status"
                  variant="success"
                  >{{ "Total" }}</b-badge
                ></label
              >
              <input
                v-model="total"
                type="text"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-1 mt-3">
              <label for="" class="fonte2">
                <b-badge
                  data-name="credito"
                  class="field-status"
                  variant="info"
                  >{{ "Desconto" }}</b-badge
                ></label
              >
              <input
                v-model="desconto"
                type="text"
                class="form-control text-left"
                placeholder=" %"
                @input="calculo_desconto()"
              />
            </div>
            <div class="col-md-2 mt-3">
              <Label>&nbsp;</Label>
              <input
                disabled
                v-model="novo_vlr_desconto"
                type="text"
                class="form-control text-left"
                placeholder="%"
              />
            </div>
            <div class="col-md-2 ml-4 mt-3">
              <label for="" class="fonte2">
                <b-badge
                  data-name="credito"
                  class="field-status"
                  variant="success"
                  >{{ "SubTotal" }}</b-badge
                ></label
              >
              <input
                v-model="subtotal"
                type="text"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-1 mt-3 ml-4">
              <label for="" class="fonte2">
                <b-badge
                  data-name="credito"
                  class="field-status"
                  variant="info"
                  >{{ "juros" }}</b-badge
                ></label
              >
              <input
                v-model="juros"
                type="text"
                class="form-control text-left"
                placeholder="%"
                @input="calculo_juros()"
              />
            </div>
            <div class="col-md-3 mt-3">
              <Label>&nbsp;</Label>
              <input
                v-model="novo_vlr_subTot"
                type="text"
                class="form-control text-left"
                placeholder="%"
              />
            </div>
          </div>
          <div
            class="row d-flex justify-content-center align-items-center mt-4"
          >
            <div class="col-md-12 text-right">
              <span class="fonte">
                <b-badge
                  data-name="credito"
                  class="field-status"
                  variant="success"
                  >{{ "Valor Pago : " + subtotal }}</b-badge
                >
              </span>
            </div>
          </div>
        </div>
        <!-- fim card-body --->

        <div class="card-body">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-12 text-center">
              <button class="btn btn-secondary" @click="setPut()">
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: {
    oldpago: { type: Object },
    empresa: { type: Object },
    abre_modal: { Boolean },
  },
  data() {
    return {
      select_conta:null,
      SubTotal: "1.000,00",
      Total: "1.000,00",
      contas: [
        {
          id: 1,
          nome: "conta 01",
        },
        {
          id: 2,
          nome: "conta 02",
        },
      ],
      select_pagamento: null,
      nome: "pagametno",
      show_modal_pagamento: false,
      pagamento_objeto: {},
      // criar um get para preenher isso
      especie_pagamentos: [
        {
          id: 1,
          nome: "especie 1",
        },
        {
          id: 1,
          nome: "especie 1",
        },
      ],
      total: null,
      subtotal: null,
      desconto: null,
      juros: null,
      novo_vlr_desconto:null,
      novo_vlr_subTot:null
    };
  },
  created() {
    this.show_modal_pagamento = this.abre_modal;
    this.pagamento_objeto = this.oldpago;
    this.total = this.oldpago.valor;
    this.subtotal = this.oldpago.valor;
  },

  mounted() {
    console.log(this.oldpago);
    this.inicial_valores();
  },

  methods: {
    selecionar_conta(event){
        console.log(event)
    },
    calculo_desconto() {
      let desconto = this.desconto;

      if (typeof desconto == "string") {
        if (desconto.includes(".") && desconto.includes(",")) {
          desconto = parseFloat(desconto.replace(".", "").replace(",", "."));
        } else if (!desconto.includes(".") && desconto.includes(",")) {
          desconto = parseFloat(desconto.replace(",", "."));
        }
      } else {
        if (typeof this.desconto === "number") {
          desconto = this.desconto;
        }
      }
      let novo_total;
      if (typeof this.total === "number") {
        let total = this.total;
        novo_total = total * (1 - (desconto / 100));
        this.novo_vlr_desconto =  novo_total
        this.subtotal = novo_total
      }
      console.log(novo_total);
    },
    calculo_juros() {
      let juros = this.juros

      if(typeof juros === 'string' ){
        if(juros.includes('.') && juros.includes(',') ){
         juros = juros.replace('.','').replace(',','.')
        }else if((!juros.includes('.')) && juros.includes(',')){
          juros = parseFloat(juros.replace(',','.'))
        }
      }else {
        if(typeof juros === 'number'){
          juros = this.juros
        }
      }
      let novo_subtotal

      if(typeof this.subtotal === 'number'){
        let subtotal = this.subtotal
        novo_subtotal = subtotal * (1+(juros / 100))
        this.novo_vlr_subTot = novo_subtotal
      }
     console.log(novo_subtotal)
    },
    inicial_valores() {
      let objeto_pagamento = this.especie_pagamentos.find(
        (e) => e.id == this.pagamento_objeto.tipo_pagamento
      );
      this.select_pagamento = objeto_pagamento;
      console.log(objeto_pagamento);
    },
    selecionar_Pagamento(event) {
      this.pagamento_objeto.tipo_pagamento = event.id;
    },
    voltar() {
      this.show_modal_pagamento = false;
      this.$emit("back");
    },
    setPut() {
      this.pagamento_objeto.pago = 1;
      this.$emit("doPut", this.pagamento_objeto);
    },
  },
};
</script>

<style scoped>
.larg {
  width: 100%;
}
.fonte {
  font-size: 40px;
}
.estilo_input {
  padding: 0.375rem 0.75rem;
  width: 80px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>