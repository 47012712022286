<script>
import Multiselect from "vue-multiselect";
//import required from "vuelidate/lib/validators";

export default {
  props: {
    oldpago: { type: Object, required: true },
    historicos: { type: Array },
    clientes: { type: Array },
    resultados: { type: Array },
    centtros: { type: Array },
    tiposPagamentos: { type: Array }
  },
  components: { Multiselect },
  data() {
    return {
      loader: { get: false },
      titleBody: 'Alterar Contas a Pagar',
      pago: {},
      conta:{},
      pagamentos:[
        'paga1',
        'paga2',
        'paga3',
      ],
      fields: [
        { label: "Numero", key: "numero", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Tipo de Pagamento", key: "tipo_pagamento", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Historico Padrão", key: "historico", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Emissão", key: "emissão", tdClass: 'text-right', thClass: 'text-center', formatter: "formatterCurrBR" },
        { label: "Vencimento", key: "vencimento", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Juros", key: "juros", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Multa", key: "multa", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Total Duplicata", key: "duplicata", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Pago", key: "pago", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Pago em", key: "pago_em", tdClass: 'text-right', thClass: 'text-center' },
        { label: "pago Cheque", key: "pago_cheque", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      parcelas_vet:[],
      credito:null,
      debito:null, 
      cliente:null, 
      historico:null, 
      pagamento:null, 
    }
  },
  validations: {
 
  },
  created() {
    this.get_historicos = this.historicos
    this.get_clientes = this.clientes
    this.res = this.resultados
    this.cent = this.centtros
    this.pagamentos = this.tiposPagamentos
    this.pago = { ...this.oldpago };
    
  },
  mounted() {
    this.setNewTitle();
    console.log(this.pago)
    this.historicos_verifica();
    this.cliente_verifica();
    this.debito_verifica();
    this.credito_verifica();
    this.pagamento_verifica();
  },
  methods: {
    historicos_verifica(){
      //console.log(this.banco.historico_cod)
      const historicoSelecionado = this.get_historicos.find((h) => h.id === this.pago.historico_cod);
      //console.log(historicoSelecionado)
      this.historico = historicoSelecionado
    },
    cliente_verifica(){
      //console.log(this.banco.historico_cod)
      const clienteSelecionado = this.get_clientes.find((c) => c.id === this.pago.cliente_cod);
      //console.log(clienteSelecionado)
      this.cliente = clienteSelecionado
    },
    debito_verifica(){
      //console.log(this.banco.historico_cod)
      const debitoSelecionado = this.cent.find((c) => c.id === this.pago.debito);
      //console.log(debitoSelecionado)
      this.debito = debitoSelecionado
    },
    credito_verifica(){
      console.log(this.pago.credito)
      const creditoSelecionado = this.res.find((r) => r.id === this.pago.credito);
      console.log(creditoSelecionado)
      this.credito = creditoSelecionado
    },
    pagamento_verifica(){
      //console.log(this.banco.historico_cod)
      const pagamentoSelecionado = this.pagamentos.find((p) => p.id === this.pago.tipo_pagamento);
      console.log(pagamentoSelecionado)
      this.pagamento = pagamentoSelecionado
    },
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      console.log('Gravou');
      console.log(this.pago)
      this.$emit('doPut', this.pago);
    },
    customLabelComposicao({ id, identificacao }) {
      return `${id} – ${identificacao}`
    },

    customLabelHistorico({ id, descricao }) {
      return `${id} – ${descricao}`
    },
    customLabelPagamento({ id, tiponome }) {
      return `${id} – ${tiponome}`
    },

    customLabelCliente({ id, pes_fantasia }) {
      return `${id} – ${pes_fantasia}`
    },
  },

}
</script>
<template>
  <div class="card">
    <div class="card-body">
      <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
      <form id="form-tipo-movimento" role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-body>
            <h4>Contas a Pagar</h4>
            <div class="form-row form-group" role="group">
              <!-- <div class="col-sm-12 col-md-2">
                <div class="form-group">
                  <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                  <div class="col">
                    <multiselect label="id" :options="get_historicos" v-model="historicos_cod"
                      @select="select_hist_nome($event)">
                    </multiselect>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                  <div class="col">
                    <!-- <multiselect label="descricao" :options="get_historicos" v-model="historicos_1"
                      @select="select_hist_cod($event)">
                    </multiselect> -->
                    <multiselect v-model="historico"  placeholder="Selecione" label="descricao" track-by="descricao"
                      :options="get_historicos" :option-height="104" :custom-label="customLabelHistorico"
                      :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER"
                      @select="select_hist_cod($event)">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.descricao }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.descricao }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <!-- <div class="col-sm-12 col-md-2">
                <div class="form-group">
                  <label for="tipo_pago_codigo" class="col-form-label">Cliente</label>
                  <div class="col">
                    <multiselect label="id" :options="get_clientes" v-model="cliente_cod"
                      @select="select_cli_cod($event)">
                    </multiselect>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="tipo_pago_codigo" class="col-form-label">Cliente</label>
                  <div class="col">
                    <!-- <multiselect label="pes_fantasia" :options="get_clientes" v-model="cliente_cod"
                      @select="select_cli_nome($event)">
                    </multiselect> -->
                    <multiselect v-model="cliente"  placeholder="Selecione" label="pes_fantasia"
                      track-by="pes_fantasia" :options="get_clientes" :option-height="104"
                      :custom-label="customLabelCliente" :show-labels="false" deselect-label="ENTER para remover"
                      select-label="Pressione ENTER" @select="select_cli_nome($event)">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.pes_fantasia }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.pes_fantasia }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_pago_digito" class="col-form-label">Forma de pagamento</label>
                  <div class="col">
                    <!-- <Multiselect :options="pagamentos" label="tiponome">

                    </Multiselect> -->
                    <multiselect v-model="pagamento"  placeholder="Selecione" label="tiponome" track-by="tiponome"
                      :options="pagamentos" :option-height="104" :custom-label="customLabelPagamento" :show-labels="false"
                      deselect-label="ENTER para remover" select-label="Pressione ENTER">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.tiponome }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.tiponome }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_pago_digito" class="col-form-label">Número do Documento</label>
                  <div class="col">
                    <input v-model="pago.num_doc" class="form-control text-left" type="text" placeholder="digite o número do documento"
                      id="codigo">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_pago_digito" class="col-form-label">Valor</label>
                  <div class="col">
                    <input v-model="pago.valor"  class="form-control text-left" type="text"
                      placeholder="Digite o Valor" id="codigo" @input="formatInput_valor_info()">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_pago_digito" class="col-form-label">Quantidade de Parcelas</label>
                  <div class="col">
                    <input v-model="pago.qtd_parcelas" class="form-control text-left" type="number" min="0"
                      placeholder="digite a Quantidade de Parcelas" id="codigo">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_pago_num" class="col-form-label">Emissão:</label>
                  <div class="col">
                    <input v-model="pago.emissao" id="searchCompras-fromData" type="datetime-local" class="form-control"
                      placeholder="dd/mm/aaaa hh:mm" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_pago_num" class="col-form-label">Data da Primeira Parcela:</label>
                  <div class="col">
                    <input v-model="pago.data_parcela" id="searchCompras-fromData" type="datetime-local" class="form-control"
                      placeholder="dd/mm/aaaa hh:mm" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_pago_num" class="col-form-label">Débito:</label>
                  <div class="col">
                    <!-- <Multiselect v-model="Conta_pagar.debito" :options="cent" label="identificacao">

                    </Multiselect> -->
                    <multiselect v-model="debito"  placeholder="Selecione" label="identificacao"
                      track-by="identificacao" :options="cent" :option-height="104" :custom-label="customLabelComposicao"
                      :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_pago_num" class="col-form-label">Crédito:</label>
                  <div class="col">
                    <!-- <Multiselect v-model="Conta_pagar.credito" :options="res" label="identificacao">

                    </Multiselect> -->
                    <multiselect v-model="credito"  placeholder="Selecione" label="identificacao"
                      track-by="identificacao" :options="res" :option-height="104" :custom-label="customLabelComposicao"
                      :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-12">
                <div class="form-group">
                  <label for="tipo_pago_orient" class="col-form-label">Observações</label>
                  <div class="col">
                    <textarea v-model="pago.obs"  name="" id="" cols="30" rows="10" class="form-control">

                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion" role="tablist">
              <form role="form" class="form-horizontal">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pesquisar variant="light">Parcelas:</b-button>
                  </b-card-header>
                  <!-- visible -->
                  <b-collapse id="pesquisar" accordion="pesquisar" role="tabpanel">
                    <b-card-body>
                      <label for="cartao_in_id" class="col-form-label">Parcelas:</label>
                      <div class="col">
                        <div>
                          <b-table striped hover :items="parcelas_vet" responsive id="contas-receber" :fields="fields">
                          </b-table>
                        </div>
                        <b-table-simple responsive>
                          <b-thead id="b-head">
                            <b-tr>
                              <b-th class="b-th">Numero</b-th>
                              <b-th class="b-th">Tipo de Pagamento</b-th>
                              <b-th class="b-th">Historico Padrão</b-th>
                              <b-th class="b-th">Emissão</b-th>
                              <b-th class="b-th">Vencimento</b-th>
                              <b-th class="b-th">Valor</b-th>
                              <b-th class="b-th">Juros</b-th>
                              <b-th class="b-th">Multa</b-th>
                              <b-th class="b-th">Total Duplicata</b-th>
                              <b-th class="b-th">Pago</b-th>
                              <b-th class="b-th">Pago em</b-th>
                              <b-th class="b-th">pago Cheque</b-th>
                              <b-th class="b-th">&nbsp;</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>

                            <b-tr>
                              <b-td>
                                <input  id="espaco" type="text" class="form-control text-left">
                              </b-td>
                              <b-td>
                                <div class="custom-multiselect-wrapper">
                                  <multiselect  placeholder="Selecione" label="tiponome"
                                    track-by="tiponome" :options="pagamentos" :option-height="104"
                                    :custom-label="customLabelPagamento" :show-labels="false"
                                    deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                    <template slot="singleLabel" slot-scope="props">
                                      <span class="option__desc">
                                        <span class="option__title">{{ props.option.id }} - {{
                                          props.option.tiponome }}</span>
                                      </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                      <div class="option__desc">
                                        <span class="option__title">{{ props.option.id }} - {{
                                          props.option.tiponome }}</span>
                                      </div>
                                    </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                  </multiselect>
                                </div>
                              </b-td>
                              <b-td>
                                <div class="custom-multiselect-wrapper">
                                  <multiselect  placeholder="Selecione" label="descricao"
                                    track-by="descricao" :options="get_historicos" :option-height="104"
                                    :custom-label="customLabelHistorico" :show-labels="false"
                                    deselect-label="ENTER para remover" select-label="Pressione ENTER"
                                    @select="select_hist_cod($event)">
                                    <template slot="singleLabel" slot-scope="props">
                                      <span class="option__desc">
                                        <span class="option__title">{{ props.option.id }} - {{
                                          props.option.descricao }}</span>
                                      </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                      <div class="option__desc">
                                        <span class="option__title">{{ props.option.id }} - {{
                                          props.option.descricao }}</span>
                                      </div>
                                    </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                  </multiselect>
                                </div>
                              </b-td>
                              <b-td>
                                <input  id="searchCompras-fromData" type="datetime-local"
                                  class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                              </b-td>
                              <b-td>
                                <input  id="searchCompras-fromData" type="datetime-local"
                                  class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                              </b-td>
                              <b-td>
                                <input  id="espaco" type="text" class="form-control text-right"
                                  @input="formatInput_valor_parcelas()">
                              </b-td>
                              <b-td>

                                <input @input="formatInput_juros_parcelas()"  id="espaco"
                                  type="text" class="form-control text-right">
                              </b-td>
                              <b-td>

                                <input @input="formatInput_multa_parcelas()"  id="espaco"
                                  type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input @input="formatInput_total_duplicata_parcelas()" 
                                  id="espaco" type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input  id="espaco" type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input  id="searchCompras-fromData" type="datetime-local"
                                  class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                              </b-td>
                              <b-td>
                                <input  id="espaco" type="text"
                                  class="form-control text-right">
                              </b-td>
                              <b-td>
                                <button class="btn btn-secondary" @click="add_parcelas($event)">Incluir</button>
                              </b-td>
                              <b-td>
                                <button class="btn btn-secondary" @click.prevent="remove($event)">Excluir</button>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </b-card-body>
                  </b-collapse>
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pesquisar1 variant="light">Plano de Contas:</b-button>
                  </b-card-header>
                  <!-- visible -->
                  <b-collapse id="pesquisar1" accordion="pesquisar" role="tabpanel">
                    <b-card-body>
                      <div class="form-row form-group" role="group">
                        <div class="col-sm-12 col-md-12" id="desc_ID">
                          <div class="form-group">
                            <label for="cartao_in_id" class="col-form-label">&nbsp;</label>
                            <div class="col">
                              <b-table-simple responsive>
                                <b-thead id="b-head">
                                  <b-tr>
                                    <b-th class="b-th">Código</b-th>
                                    <b-th class="b-th">C.C ID</b-th>
                                    <b-th class="b-th">Débito</b-th>
                                    <b-th class="b-th">C.R Id</b-th>
                                    <b-th class="b-th">Crédito</b-th>
                                    <b-th class="b-th">Valor</b-th>
                                    <b-th class="b-th">Ativo</b-th>
                                    <b-th class="b-th">&nbsp;</b-th>
                                  </b-tr>
                                </b-thead>
                                <b-tbody>
                                  <b-tr>
                                    <b-td>
                                      <input  id="espaco" type="text"
                                        class="form-control text-left">
                                    </b-td>
                                    <b-td>
                                      <input  id="espaco" type="text"
                                        class="form-control text-left">
                                    </b-td>
                                    <b-td id="espaco">
                                      <div class="custom-multiselect-wrapper">
                                        <!-- <multiselect :options="res" label="identificacao">
                                        </multiselect> -->
                                        <multiselect  placeholder="Selecione"
                                          label="identificacao" track-by="identificacao" :options="res"
                                          :option-height="104" :custom-label="customLabelComposicao" :show-labels="false"
                                          deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                          <template slot="singleLabel" slot-scope="props">
                                            <span class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </span>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </div>
                                          </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                        </multiselect>
                                      </div>
                                    </b-td>
                                    <b-td>
                                      <input  id="espaco" type="text"
                                        class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <div class="custom-multiselect-wrapper">
                                        <!-- <multiselect  :options="cent" label="identificacao">
                                        </multiselect> -->

                                        <multiselect  placeholder="Selecione"
                                          label="identificacao" track-by="identificacao" :options="cent"
                                          :option-height="104" :custom-label="customLabelComposicao" :show-labels="false"
                                          deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                          <template slot="singleLabel" slot-scope="props">
                                            <span class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </span>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </div>
                                          </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                        </multiselect>
                                      </div>
                                    </b-td>
                                    <b-td>
                                      <input @input="formatInput_valor_plano_contas()" 
                                        id="espaco" type="text" class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <input  id="espaco" type="text"
                                        class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <button class="btn btn-secondary">Incluir</button>
                                    </b-td>
                                  </b-tr>
                                </b-tbody>
                              </b-table-simple>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </form>
            </div>
          </b-card-body>
        </b-card>
      </form>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="setPut">Gravar</button>
      </div>
    </div>
  </div>
</template>
