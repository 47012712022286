<script>
export default {
  props: {
    pagos: { type: Object }
  },
}
</script>

<template>
<div id="pagos">
  <!-- 'historico_cod',
        'cliente_nome',
        'cliente_cod',
        'tipo_pagamento',
        'num_doc',
        'valor',
        'qtd_parcelas',
        'emissao',
        'data_parcela',
        'data_fatura',
        'cod_cupom', -->
  <p>Nome: {{ pagos.cliente_nome }}</p>
  <p>Tipo Pagamento: {{ pagos.tipo_pagamento}} </p>
  <p>Valor: {{ pagos.valor}} </p>
  <p>Numero: {{ pagos.num_doc}} </p>
  <p>Quantidade de Parcelas: {{ pagos.qtd_parcelas}} </p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ pagos.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ pagos.updated_at }}</b-badge></p>
</div>
</template>
